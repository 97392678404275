<template>
  <div>
    <v-row dense>
      <v-col>
        <select-multiple
          v-model="filters.id_sources"
          :options="sourceOptions"
          label="Mã nguồn"
        ></select-multiple>
      </v-col>
      <v-col>
        <DateRangeFilter
          :default-date="filters.date"
          label="Thời gian"
          placeholder="Thời gian"
          name="date"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col>
        <SelectFilter
          :options="dataTypes"
          :default-value="filters.data_type"
          label="Dữ liệu"
          placeholder="Dữ liệu"
          name="data_type"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col>
        <SelectFilter
          :options="workTimes"
          :default-value="filters.work_time"
          label="Ca"
          placeholder="Ca"
          name="work_time"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col>
        <SelectFilter
          :options="employeeTypes"
          :default-value="filters.employee_type"
          label="Nhân viên"
          placeholder="Nhân viên"
          name="employee_type"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col>
        <SelectFilter
          :options="quantityTypes"
          :default-value="filters.quantity_type"
          label="Phân loại"
          placeholder="Phân loại"
          name="quantity_type"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col>
        <v-btn color="primary" @click="getList" block style="height: 40px">
          Update sau {{ countDown }}s
        </v-btn>
      </v-col>
    </v-row>

    <div>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">Thời gian</th>
              <th role="columnheader" class="text-center">Phân loại</th>
              <th role="columnheader" class="text-center">Mã nguồn</th>
              <th role="columnheader" class="text-center">Ca</th>
              <th role="columnheader" class="text-center">
                Nhân viên:
                <br />
                <small class="warning--text">
                  E = {{ formatNumber(totalEmployee) }}
                </small>
              </th>
              <!-- <th role="columnheader" class="text-center">
                                Chấm công
                            </th> -->
              <template v-if="filters.quantity_type == 'item'">
                <th role="columnheader" class="text-center">
                  Nhập kho:
                  <span class="warning--text">
                    {{ formatNumber(sumAll["nhap_kho"]) }}
                  </span>
                  <br />
                  <small class="warning--text">
                    E = {{ formatNumber(sumAll["nhap_kho_emp"]) }}
                  </small>
                </th>
                <th role="columnheader" class="text-center">
                  Lên kệ:
                  <span class="warning--text">
                    {{ formatNumber(sumAll["len_ke"]) }}
                  </span>
                  <br />
                  <small class="warning--text">
                    E = {{ formatNumber(sumAll["len_ke_emp"]) }}
                  </small>
                </th>
              </template>
              <th role="columnheader" class="text-center">
                Pickup:
                <span class="primary--text">
                  {{ formatNumber(sumAll["pickup"]) }}
                </span>
                <br />
                <small class="primary--text">
                  E = {{ formatNumber(sumAll["pickup_emp"]) }}
                </small>
              </th>
              <th role="columnheader" class="text-center">
                Đóng gói:
                <span class="primary--text">
                  {{ formatNumber(sumAll["dong_goi"]) }}
                </span>
                <br />
                <small class="primary--text">
                  E = {{ formatNumber(sumAll["dong_goi_emp"]) }}
                </small>
              </th>
              <th role="columnheader" class="text-center">
                Bàn giao:
                <span class="success--text">
                  {{ formatNumber(sumAll["ban_giao"]) }}
                </span>
                <br />
                <small class="success--text">
                  E = {{ formatNumber(sumAll["ban_giao_emp"]) }}
                </small>
              </th>
              <th role="columnheader" class="text-center">
                Nhận hoàn:
                <span class="warning--text">
                  {{ formatNumber(sumAll["nhan_hoan"]) }}
                </span>
                <br />
                <small class="warning--text">
                  E = {{ formatNumber(sumAll["nhan_hoan_emp"]) }}
                </small>
              </th>
              <th role="columnheader" class="text-center">
                Nhập hoàn:
                <span class="success--text">
                  {{ formatNumber(sumAll["nhap_hoan"]) }}
                </span>
                <br />
                <small class="success--text">
                  E = {{ formatNumber(sumAll["nhap_hoan_emp"]) }}
                </small>
              </th>
              <template v-if="filters.quantity_type == 'item'">
                <th role="columnheader" class="text-center">
                  Lên kệ hoàn:
                  <span class="success--text">
                    {{ formatNumber(sumAll["len_ke_hoan"]) }}
                  </span>
                  <br />
                  <small class="success--text">
                    E = {{ formatNumber(sumAll["len_ke_hoan_emp"]) }}
                  </small>
                </th>
                <!-- <th role="columnheader" class="text-center">
                                    AVG. Nhân sự
                                </th> -->
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`s_${key}`"
              class="text-center tr-h-36px"
            >
              <td>{{ item.date }}</td>
              <td>
                {{ filters.quantity_type == "item" ? "Sản phẩm" : "Đơn hàng" }}
              </td>
              <td>{{ item.source_code }}</td>
              <td>{{ item.shift }}</td>
              <td>{{ item.employee }}</td>
              <!-- <td>
                                E = {{ formatNumber(item.count_employee) }}
                            </td> -->
              <template v-if="filters.quantity_type == 'item'">
                <td>
                  <template v-if="item.nhap_kho > 0 || item.nhap_kho_emp > 0">
                    {{ formatNumber(item.nhap_kho) }} <br />
                    <small v-if="filters.employee_type == 'summary'">
                      E = {{ formatNumber(item.nhap_kho_emp) }}
                    </small>
                    <small v-else class="warning--text">
                      {{ calPercent(item.nhap_kho, "nhap_kho") }}
                    </small>
                  </template>
                </td>
                <td>
                  <template v-if="item.len_ke > 0 || item.len_ke_emp > 0">
                    {{ formatNumber(item.len_ke) }} <br />
                    <small v-if="filters.employee_type == 'summary'">
                      E = {{ formatNumber(item.len_ke_emp) }}
                    </small>
                    <small v-else class="warning--text">
                      {{ calPercent(item.len_ke, "len_ke") }}
                    </small>
                  </template>
                </td>
              </template>
              <td>
                <template v-if="item.pickup > 0 || item.pickup_emp > 0">
                  {{ formatNumber(item.pickup) }} <br />
                  <small v-if="filters.employee_type == 'summary'">
                    E = {{ formatNumber(item.pickup_emp) }}
                  </small>
                  <small v-else class="primary--text">
                    {{ calPercent(item.pickup, "pickup") }}
                  </small>
                </template>
              </td>
              <td>
                <template v-if="item.dong_goi > 0 || item.dong_goi_emp > 0">
                  {{ formatNumber(item.dong_goi) }} <br />
                  <small v-if="filters.employee_type == 'summary'">
                    E = {{ formatNumber(item.dong_goi_emp) }}
                  </small>
                  <small v-else class="primary--text">
                    {{ calPercent(item.dong_goi, "dong_goi") }}
                  </small>
                </template>
              </td>
              <td>
                <template v-if="item.ban_giao > 0 || item.ban_giao_emp > 0">
                  {{ formatNumber(item.ban_giao) }} <br />
                  <small v-if="filters.employee_type == 'summary'">
                    E = {{ formatNumber(item.ban_giao_emp) }}
                  </small>
                  <small v-else class="success--text">
                    {{ calPercent(item.ban_giao, "ban_giao") }}
                  </small>
                </template>
              </td>
              <td>
                <template v-if="item.nhan_hoan > 0 || item.nhan_hoan_emp > 0">
                  {{ formatNumber(item.nhan_hoan) }} <br />
                  <small v-if="filters.employee_type == 'summary'">
                    E = {{ formatNumber(item.nhan_hoan_emp) }}
                  </small>
                  <small v-else class="warning--text">
                    {{ calPercent(item.nhan_hoan, "nhan_hoan") }}
                  </small>
                </template>
              </td>
              <td>
                <template v-if="item.nhap_hoan > 0 || item.nhap_hoan_emp > 0">
                  {{ formatNumber(item.nhap_hoan) }} <br />
                  <small v-if="filters.employee_type == 'summary'">
                    E = {{ formatNumber(item.nhap_hoan_emp) }}
                  </small>
                  <small v-else class="success--text">
                    {{ calPercent(item.nhap_hoan, "nhap_hoan") }}
                  </small>
                </template>
              </td>
              <template v-if="filters.quantity_type == 'item'">
                <td>
                  <template
                    v-if="item.len_ke_hoan > 0 || item.len_ke_hoan_emp > 0"
                  >
                    {{ formatNumber(item.len_ke_hoan) }} <br />
                    <small v-if="filters.employee_type == 'summary'">
                      E = {{ formatNumber(item.len_ke_hoan_emp) }}
                    </small>
                    <small v-else class="success--text">
                      {{ calPercent(item.len_ke_hoan, "len_ke_hoan") }}
                    </small>
                  </template>
                </td>
                <!-- <td>{{ formatNumber(itemSum(item)) }}</td> -->
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import moment from "moment";

export default {
  name: "DashboardHistory",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  props: {
    tab: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    countDown: 0,
    timeoutCountDown: 0,
    totalEmployee: 0,
    items: [],
    filters: {
      id_sources: [],
      date: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      employee_type: "summary",
      data_type: "summary",
      work_time: "summary",
      quantity_type: "order",
    },
    employeeTypes: [
      {
        text: "Tổng hợp",
        value: "summary",
      },
      {
        text: "Theo nhân viên",
        value: "employee",
      },
    ],
    dataTypes: [
      {
        text: "Tổng hợp",
        value: "summary",
      },
      {
        text: "Theo ngày",
        value: "date",
      },
    ],
    workTimes: [
      {
        text: "Tổng hợp",
        value: "summary",
      },
      {
        text: "Theo ca",
        value: "shift",
      },
    ],
    quantityTypes: [
      {
        text: "Sản phẩm",
        value: "item",
      },
      {
        text: "Đơn hàng",
        value: "order",
      },
    ],
  }),
  computed: {
    sourceOptions() {
      const { poss, warehouses } = window.me;
      if (this.tab === "pos-history") {
        return [...poss].map((p) => ({
          value: p.id,
          text: p.code || "",
        }));
      } else {
        return [...warehouses].map((w) => ({
          value: w.id,
          text: w.code_name || "",
        }));
      }
    },
    itemSum() {
      return (item) =>
        (+item.nhap_kho +
          +item.len_ke +
          +item.pickup +
          +item.dong_goi +
          +item.ban_giao +
          +item.nhan_hoan +
          +item.nhap_hoan +
          +item.len_ke_hoan) /
        item.count_employee;
    },
    sumAll() {
      const sum = {
        nhap_kho: 0,
        len_ke: 0,
        pickup: 0,
        dong_goi: 0,
        ban_giao: 0,
        nhan_hoan: 0,
        nhap_hoan: 0,
        len_ke_hoan: 0,

        nhap_kho_emp: 0,
        len_ke_emp: 0,
        pickup_emp: 0,
        dong_goi_emp: 0,
        ban_giao_emp: 0,
        nhan_hoan_emp: 0,
        nhap_hoan_emp: 0,
        len_ke_hoan_emp: 0,
      };
      if (!this.items || this.items.length === 0) {
        return sum;
      }
      this.items.forEach((item) => {
        sum.nhap_kho += +item.nhap_kho || 0;
        sum.len_ke += +item.len_ke || 0;
        sum.pickup += +item.pickup || 0;
        sum.dong_goi += +item.dong_goi || 0;
        sum.ban_giao += +item.ban_giao || 0;
        sum.nhan_hoan += +item.nhan_hoan || 0;
        sum.nhap_hoan += +item.nhap_hoan || 0;
        sum.len_ke_hoan += +item.len_ke_hoan || 0;

        sum.nhap_kho_emp += +item.nhap_kho_emp || 0;
        sum.len_ke_emp += +item.len_ke_emp || 0;
        sum.pickup_emp += +item.pickup_emp || 0;
        sum.dong_goi_emp += +item.dong_goi_emp || 0;
        sum.ban_giao_emp += +item.ban_giao_emp || 0;
        sum.nhan_hoan_emp += +item.nhan_hoan_emp || 0;
        sum.nhap_hoan_emp += +item.nhap_hoan_emp || 0;
        sum.len_ke_hoan_emp += +item.len_ke_hoan_emp || 0;
      });
      return sum;
    },
  },
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  beforeDestroy() {
    clearTimeout(this.timeoutCountDown);
  },
  methods: {
    countdownForReload() {
      if (this.countDown > 0) {
        this.timeoutCountDown = setTimeout(() => {
          this.countDown -= 1;
          this.countdownForReload();
        }, 1000);
      } else {
        this.getList();
      }
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    calPercent(value, type) {
      const sum = this.sumAll[type];
      const percent = (value / sum) * 100;
      return `${this.formatNumber(percent, 2)} %`;
    },
    getList: debounce(function () {
      clearTimeout(this.timeoutCountDown);
      httpClient
        .post("/administration/v1/admin-report-history", {
          ...this.filters,
          type: this.tab,
        })
        .then(({ data }) => {
          this.items = [...data.rows];
          this.totalEmployee = data.total_employee || 0;
          this.countDown = 60;
          this.countdownForReload();
        });
    }, 500),
  },
};
</script>

<style scoped></style>
